<template>
  <b-modal
    modal-class="audit-trail"
    dialog-class="audit-trail"
    v-model="ShowAuditTrail"
    :no-close-on-backdrop="true"
    size="xl"
  >
    <template v-slot:modal-header="{ close }">
      <h5 class="modal-title">{{ ModalTitle }}</h5>
      <button class="close" @click="Cancel()"></button>
    </template>

    <div>
      <vue-html2pdf
        :show-layout="true"
        :float-layout="false"
        :enable-download="false"
        :preview-modal="false"
        :paginate-elements-by-height="1800"
        :filename="PDFFileName"
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="a4"
        pdf-orientation="landscape"
        pdf-content-width="100%"
        @beforeDownload="beforeDownload($event)"
        ref="html2Pdf"
      >
        <section slot="pdf-content">
          <div class="request-status-container">
            <div v-if="IsHistoryAvailable" class="audit-label">Most Recent Audit Trail</div>
            <ul class="steps request-status">
              <li
                class="step"
                v-for="(status, index) in statusList"
                :key="status.Name"
                style="display: inline"
              >
                <div class="step-content">
                  <span class="step-circle">&nbsp;</span>
                  <span class="step-text">
                    <div class="status">
                      {{ FormatTask(status.TaskName, status.TaskState) }}
                    </div>
                    <div class="date">{{ FormatDate(status.UpdatedDate) }}</div>
                    <div class="updatedby">
                      {{
                        GetStatus(
                          status.TaskState,
                          status.UpdatedBy,
                          index,
                          status.TaskName
                        )
                      }}
                    </div>
                    <a
                      class="comments"
                      v-if="status.Comments"
                      :title="status.Comments"
                      v-b-tooltip.hover
                    >
                      View Comments
                    </a>
                  </span>
                </div>
              </li>
            </ul>
            <div v-if="IsHistoryAvailable && ShowHistoryStates" class="audit-label">Previous Audit Trail</div>
            <a class="btn btn-link" v-if="IsHistoryAvailable && !ShowHistoryStates" @click="GetWorkflowHistoryStates">
              <b-img :src="collapseIcon" alt="expand"></b-img> SEE PREVIOUS AUDIT TRAILS</a>
            <span v-if="ShowHistoryStates && IsHistoryAvailable">
            <ul v-for="(historyStatusList,parentIndex) in HistoryAuditTrail" :key="historyStatusList.ProcessID" class="steps request-status">
              <li
                class="step"
                v-for="(status, index) in historyStatusList.States"
                :key="status.Name"
                style="display: inline"
              >
                <div class="step-content">
                  <span class="step-circle">&nbsp;</span>
                  <span class="step-text">
                    <div class="status">
                      {{ FormatTask(status.TaskName, status.TaskState) }}
                    </div>
                    <div class="date">{{ FormatDate(status.UpdatedDate) }}</div>
                    <div class="updatedby">
                      {{
                        GetStatus(
                          status.TaskState,
                          status.UpdatedBy,
                          index,
                          status.TaskName,
                          parentIndex
                        )
                      }}
                    </div>
                    <a
                      class="comments"
                      v-if="status.Comments"
                      :title="status.Comments"
                      v-b-tooltip.hover
                    >
                      View Comments
                    </a>
                  </span>
                </div>
              </li>
            </ul>
            </span>
             <a class="btn btn-link" v-if="IsHistoryAvailable && ShowHistoryStates" @click="HideHistoryWorkflow"><b-img :src="expandIcon" alt="expand"></b-img> See less</a>
          </div>
          <client-overview
            Columns="3"
            :ClientRequest="ClientRequest"
            :ID="id"
            class="audit-main"
          ></client-overview>
        </section>
      </vue-html2pdf>
    </div>
    <template v-slot:modal-footer="{ ok, cancel }">
      <div class="text-right footer-buttons">
        <button class="btn custom-button btn-primary" @click="DownloadAsPDF()">
          Download As PDF
        </button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import moment from "moment";
import VueHtml2pdf from "vue-html2pdf";
import { AxUpdateTheRequestWithSystemCode } from "../../utilities/ClientWorkflowStates";
export default {
  components: {
    VueHtml2pdf,
  },
  name: "AuditTrail",
  props: {
    id: {
      required: true,
      type: Number,
    },
    closeAuditModal: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      collapseIcon: require("@/assets/Icons/arrow-down.svg"),
      expandIcon: require("@/assets/Icons/arrow-up-big.svg"),
      ClientRequest: null,
      USACountry: "United States",
      ShowAuditTrail: true,
      statusList: [],
      ID: null,
      HistoryProcessCount: 0,
      HistoryAuditTrail: [],
      HistoryLoaded:false,
      ShowHistoryStates:false
    };
  },
  created() {
    this.ShowAuditTrail = true;
    this.GetWorkflowStates();
    this.$store
      .dispatch("client/GetClientAuditFormData", parseInt(this.id))
      .then((res) => {
        this.ClientRequest = res.data;
        //this.statusList = res.data.States;
      })
      .catch((err) => {
        alert(err);
      });
  },
  computed: {
    IsHistoryAvailable(){
   return this.HistoryProcessCount>0;
    },
    ModalTitle() {
      return this.ClientRequest != null
        ? `Audit Trail - ${this.ClientRequest.ClientName}`
        : "Audit Trail";
    },
    IsBillingCountryUSA() {
      return this.ClientRequest.Country == this.USACountry;
    },
    PDFFileName() {
      return `${this.ModalTitle}_${moment(new Date()).format(
        "MMDDYYYYHHmmss"
      )}`;
    },
    IsRequestCompleted() {
      return (
        this.ClientRequest.CompletedCode != null &&
        this.ClientRequest.CompletedCode.length > 0
      );
    },
  },
  methods: {
    async GetWorkflowStates() {
      this.$store
        .dispatch("client/GetClientWorkflowStates", parseInt(this.id))
        .then((res) => {
          this.statusList = res.data.States;
          this.HistoryProcessCount = res.data.HistoryProcessCount;
        })
        .catch((err) => {
          alert(err);
        });
    },
    async GetWorkflowHistoryStates() {
      if(this.HistoryLoaded!=true){
      this.$store
        .dispatch("client/GetClientWorkflowHistoryStates", parseInt(this.id))
        .then((res) => {
          this.HistoryAuditTrail = res.data;
          this.HistoryLoaded=true;
          this.ShowHistoryStates=true;
        })
        .catch((err) => {
          alert(err);
        });
      }
      if(this.HistoryLoaded==true){
        this.ShowHistoryStates=true;
      }
    },
    HideHistoryWorkflow(){
this.ShowHistoryStates=false;
    },
    async beforeDownload({ html2pdf, options, pdfContent }) {
      await html2pdf()
        .set(options)
        .set({
          pagebreak: { mode: ["avoid-all", "css", "legacy"] },
        })
        .from(pdfContent)
        .toPdf()
        .save();
    },
    FormatTask(name, state) {
      if (state == "Finished" && name == AxUpdateTheRequestWithSystemCode) {
        return `${name} (${this.ClientRequest.CompletedCode})`;
      } else {
        return name;
      }
    },
    FormatDate(date) {
      return date != null && moment(new Date(date)).isValid()
        ? moment(date).format("MM/DD/YYYY")
        : "-";
    },
    GetStatus(taskStatus, updatedBy, index, taskName,parentIndex) {
      let status = "";
      let lastItem = this.statusList.length - 1 == index;
      if(parentIndex!=undefined && parentIndex!=null && parseInt(parentIndex)>-1){
        lastItem = this.HistoryAuditTrail[parentIndex].States.length - 1 == index;
      }
      let firstItem = index == 0;

      switch (taskStatus) {
        case "Finished":
          status = firstItem
            ? `Submitted by ${updatedBy}`
            : lastItem && this.IsRequestCompleted
            ? `Completed by ${updatedBy}`
            : `Approved by ${updatedBy}`;
          break;
        case "Ready":
          status =
            taskName == "Update the request with System Code"
              ? "Pending for completion"
              : `Waiting for approval`;
          break;
        case "Assigned":
          status = `Waiting for approval`;
          break;
        case "Started":
          status = ``;
          break;
        case "Canceled":
          status = lastItem
            ? `Rejected by ${updatedBy}`
            : firstItem
            ? `Submitted by ${updatedBy}`
            : `Approved by ${updatedBy}`;
          break;
        default:
          status = ``;
          break;
      }
      return status;
    },
    Cancel() {
      this.ShowAuditTrail = false;
      this.$emit("closeAudit");
    },
    DownloadAsPDF() {
      this.$refs.html2Pdf.generatePdf();
    },
  },
};
</script>

<style scoped>
.audit-label {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4d5161;
  margin-left: 2rem;
  padding-top:1rem;
}
.panel-list:not(.media-dropdowns) {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}
.panel-list .panel-body .panel-title {
  border-bottom: 1px solid rgba(151, 151, 151, 0.2);
  width: 100%;
  margin-left: 0px;
  margin-bottom: 25px !important;
}
.panel-list .panel-body .panel-title label {
  color: #77a2bb;
  margin-bottom: 0px !important;
  padding-top: 13px !important;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.57;
  letter-spacing: normal;
  padding-left: 0px !important;
  min-height: 35px;
}
.panel-list .panel-body .panel-title .btn {
  padding-left: 5px;
  padding-right: 5px;
}
.panel-list .panel-body .panel-title > div {
  padding-right: 0px !important;
  float: right;
  font-family: "Open Sans";
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.75;
  letter-spacing: normal;
  color: #00a1d2;
}
.panel-list .panel-content label {
  text-transform: uppercase;
  color: #2c3865;
  font-size: 12px;
  margin-bottom: 1px !important;
  padding-left: 0px;
}
.panel-list .panel-content p {
  text-transform: uppercase;
  color: #4d5161;
  font-size: 14px;
}
.panel-content.row > div > label:first-child {
  color: #2c3865 !important;
  font-family: "Open Sans" !important;
  font-size: 12px;
  font-weight: 600 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42;
  letter-spacing: normal;
}
.panel-content.row > div > :not(:first-child) {
  font-family: "Open Sans" !important;
  font-size: 14px !important;
}
.panel-content.row > div > div {
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #4d5161;
}
.panel-list > .panel-body {
  margin-top: 1rem !important;
}
.panel-content.row > div {
  margin-bottom: 10px !important;
}
.edit-icon {
  height: 11px;
  width: 11px;
  color: #00a1d2;
}
.request-status {
  background-color:transparent;
  margin-left: 0rem;
  margin-right: 0rem;
  display: flex;
  height: auto;
  border-radius: 5px;
  padding-left: 2rem;
}
.request-status-container {
  background-color: rgba(216, 216, 216, 0.2);
  margin-left:3rem;
  margin-right:3rem;

}
.status-bar {
  line-height: 2;
  color: #d5d7e0;
}
.request-status .status {
  font-size: 12px;
  font-family: "Open Sans";
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #4d5161;
}
.request-status .date {
  font-size: 10px;
  font-family: "Open Sans";
  font-weight: regular;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #a2a9ad;
}
.request-status .updatedby {
  font-size: 10px;
  font-family: "Open Sans";
  font-weight: regular;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #4d5161;
}
.request-status .comments {
  font-size: 12px;
  font-family: "Open Sans";
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #00a1d2;
}
.steps.request-status {
  padding-top: 20px;
}
.steps.request-status .step-text > * {
  white-space: normal;
  word-break: break-word;
}
.steps.request-status .step-content {
  min-width: 11rem !important;
  max-width: 11rem !important;
  padding-right: 0px !important;
}
</style>